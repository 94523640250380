import React, { useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"

import { useHeader } from "contexts/header"

import { CommunitySelector, CallToAction, SEO } from "components"
import { usePreviewData } from "utils"

function CommunitiesPage() {
  const data = useStaticQuery(
    graphql`
      {
        prismicCommunities {
          data {
            seo_title {
              text
            }
            seo_description {
              text
            }
            seo_image {
              url
              alt
            }
            opening_description {
              html
            }
            closing_block_image {
              url
              alt

              fluid(maxWidth: 1200) {
                ...GatsbyPrismicImageFluid
              }
            }
            closing_block_video {
              url
            }
            closing_block_title {
              text
            }
            closing_block_description {
              html
            }
            closing_block_button_text
            closing_block_button_link {
              link_type
              url
            }
            closing_block_style
            closing_block_pattern
            closing_block_color
            closing_block_side
          }
        }
      }
    `
  )

  const { setHeaderMode } = useHeader()

  useEffect(() => {
    setHeaderMode("solid")
  }, [])

  // Merge preview data with static query if possible
  const MERGED_DATA = usePreviewData(data)
  const { prismicCommunities } = MERGED_DATA

  return (
    <>
      <SEO
        title={prismicCommunities.data.seo_title}
        description={prismicCommunities.data.seo_description}
        image={prismicCommunities.data.seo_image}
      />
      <CommunitySelector
        full={true}
        subtext={prismicCommunities.data.opening_description}
      />
      <CallToAction
        image={prismicCommunities.data.closing_block_image}
        video={prismicCommunities.data.closing_block_video}
        heading={prismicCommunities.data.closing_block_title}
        text={prismicCommunities.data.closing_block_description}
        button={prismicCommunities.data.closing_block_button_text}
        link={prismicCommunities.data.closing_block_button_link}
        style={prismicCommunities.data.closing_block_style}
        pattern={prismicCommunities.data.closing_block_pattern}
        bg={prismicCommunities.data.closing_block_color}
        align={prismicCommunities.data.closing_block_side}
      />
    </>
  )
}

export default CommunitiesPage
